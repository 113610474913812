$(function() {
  var selectedClass = ""
  $(document).ready(function() {
    var hash = window.location.hash
    if (hash !== "") {
      setHash(hash.replace("#", ""))
    } else {
      console.log("all")
      setHash("all")
    }
  })

  $(".filters button").click(function() {
    selectedClass = $(this).attr("data-rel")
    //$(".container").fadeTo(100, 0.1);
    window.location.hash = selectedClass

    setHash(selectedClass)
  })

  function setHash(value) {
    $(".filters button").removeClass("active")
    $("button[data-rel*=" + value + "]").addClass("active")

    $(".container div.item")
      .not("." + value)
      .fadeOut()
      .removeClass("scale-anm")
    setTimeout(function() {
      $("." + value)
        .fadeIn()
        .addClass("scale-anm")
      //$("#container").fadeTo(300, 1);
    }, 300)
  }
})
